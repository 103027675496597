import {Link} from "gatsby"
import {StaticImage} from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/layout"

const partners = () => {
    return (
        <Layout>
            <div className="mb-20">
                {/* Partners Header */}
                <div className="bg-blue-500 pb-12 mb-10">
                    <div className="container mx-auto pt-24 pb-8 text-center px-8 md:w-1/2">
                        <h1 className="text-2xl md:text-5xl text-white font-normal leading-tight">
                            The Digital Entrepreneurship Ecosystem Funding Partners
                        </h1>
                    </div>
                </div>

                {/* Individual Partners */}
                {/* DSTI */}
                <div className="mt-20">
                    <div className="flex justify-center mx-8" id="dsti">
                        <div
                            className="grid md:flex md:w-2/3 md:justify-center shadow-md border-t-4 border-blue-500 rounded overflow-hidden">
                            <div className="mx-8 md:w-1/3">
                                <StaticImage src="../images/footer/dsti.jpeg" alt="DSTI"/>
                            </div>
                            <div className="md:w-2/3 py-10 px-12">
                                <h2>DSTI</h2>
                                <p className="text-justify">
                                    The Vision of the Directorate of Science Technology and
                                    Innovation is to use science, technology and innovation to
                                    support the Government of Sierra Leone to deliver on its
                                    national development plan effectively and efficiently; and to
                                    help transform Sierra Leone into an innovation and
                                    entrepreneurship hub. The Directorate of Science, Technology
                                    and Innovation (DSTI) sits in the Office of the President and
                                    executes its functions through the Office of the Chief
                                    Minister. To execute on its mission and objectives, DSTI seeks
                                    guidance and external support by working closely with two
                                    active Advisory Groups: The Inter-Governmental Advisory Board
                                    and the Citizen’s Advisory Group.
                                </p>
                                <Link
                                    className="text-blue-500"
                                    to="https://www.dsti.gov.sl/"
                                    target="blank"
                                >
                                    dsti.gov.sl
                                </Link>
                            </div>
                        </div>
                    </div>

                    {/* SMEDA */}
                    <div className="flex justify-center mx-8 mt-20" id="smeda">
                        <div
                            className="grid md:flex md:w-2/3 md:justify-center shadow-md border-t-4 border-blue-500 rounded overflow-hidden">
                            <div className="mx-8 md:w-1/3">
                                <StaticImage
                                    src="../images/footer/smeda.jpeg"
                                    alt="SMEDA"
                                    className="mt-10 ml-6"
                                />
                            </div>
                            <div className="md:w-2/3 py-10 px-12">
                                <h2>SMEDA</h2>
                                <p className="text-justify">
                                    Small and Medium Enterprises Development Agency’s core mandate
                                    is the promotion of a conducive business environment,
                                    including an efficient and effective service delivery network,
                                    to empower and develop Small and Medium Enterprises for
                                    growth, productivity and competitiveness. The agency operates
                                    within five strategic pillars: coordinating Government SME
                                    interventions, developing SME Observatory and Database through
                                    research and statistical data, providing Business Development
                                    Services, Facilitating Access to Finance, and Promoting
                                    Entrepreneurial Culture.
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* SLEDP */}
                    <div className="flex justify-center mx-8 mt-20" id="sledp">
                        <div
                            className="grid md:flex md:w-2/3 md:justify-center shadow-md border-t-4 border-blue-500 rounded overflow-hidden">
                            <div className="mx-8 md:w-1/3">
                                <StaticImage
                                    src="../images/footer/sledp.jpeg"
                                    alt="SLEDP"
                                    className=" mt-2"
                                />
                            </div>
                            <div className="md:w-2/3 py-10 px-12">
                                <h2 className="text-blue-500">SLEDP</h2>
                                <p className="text-justify">
                                    The Sierra Leone Economic Diversification Project (SLEDP) is
                                    designed to support the growth of SMEs and Entrepreneurs in
                                    Sierra Leone to enhance domestic and international investment
                                    and increased revenue from non-mining sectors. SLEDP addresses
                                    three key constraints of:
                                    <ul>
                                        <li>
                                            <b>(i)</b> weak governance and burdensome regulation;
                                        </li>
                                        <li>
                                            <b>(ii)</b> poor entre- preneurship ecosystem and low
                                            skills base; and
                                        </li>
                                        <li>
                                            <b>(iii)</b> low-quality infrastructure and addresses
                                            these constraints with the following solutions: business
                                            environment reforms; SME and entrepreneurship support,
                                            public goods invest- ments, and public sector capacity
                                            building.
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* UNCDF */}
                    <div className="flex justify-center mt-20" id="uncdf">
                        <div
                            className="grid md:flex md:w-2/3 md:justify-center shadow-md border-t-4 border-blue-500 rounded overflow-hidden">
                            <div className="mx-8 md:w-1/3">
                                <StaticImage
                                    src="../images/footer/uncdf-logo.jpeg"
                                    alt="UNCDF"
                                />
                            </div>
                            <div className="w-2/3 px-12 pt-10">
                                <h2 className="text-blue-500">
                                    THE UN CAPITAL DEVELOPMENT FUND
                                </h2>
                                <p className="text-justify">
                                    The UN Capital Development Fund (UNCDF) makes public and
                                    private finance work for the poor in the world's 47 least
                                    developed countries. With its capital mandate and instruments,
                                    UNCDF offers "last mile" finance models that unlock public and
                                    private resources, especially at the domestic level, to reduce
                                    poverty and support local economic development.
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* UNDP */}
                    <div className="flex justify-center mt-20" id="undp">
                        <div
                            className="grid md:flex md:w-2/3 md:justify-center shadow-md border-t-4 border-blue-500 rounded overflow-hidden">
                            <div className="mx-8 md:w-1/3 md:flex">
                                <StaticImage
                                    src="../images/footer/undp-logo-big.jpeg"
                                    alt="UNDP"
                                    width={200}
                                />
                            </div>
                            <div className="md:w-2/3 py-10 px-12">
                                <h2 className="text-blue-500">UNDP</h2>
                                <p className="text-justify">
                                    UNDP’s mandate is to end poverty, build democratic governance,
                                    rule of law, and inclusive institutions. They advocate for
                                    change and connect countries to knowledge, experience and
                                    resources to help people build a better life. UNDP works in
                                    170 countries and territories to eradicate poverty and reduce
                                    inequality. They help countries to develop policies,
                                    leadership skills, partnering abilities, institutional
                                    capabilities, and to build resilience to achieve the
                                    Sustainable Development Goals. Their work is concentrated in
                                    three focus areas; sustainable development, democratic
                                    governance and peacebuilding, and climate and disaster
                                    resilience. (Source:{" "}
                                    <a
                                        href="https://www.sl.undp.org/content/sierraleone/en/home/"
                                        target="blank"
                                    >
                                        undp.org
                                    </a>
                                    ).
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* INVEST SALONE */}
                    <div className="flex justify-center mt-20" id="invest-salone">
                        <div
                            className="grid md:flex md:w-2/3 md:justify-center shadow-md border-t-4 border-blue-500 rounded overflow-hidden">
                            <div className="mx-8 md:w-1/3">
                                <StaticImage
                                    src="../images/footer/invest-salone.jpeg"
                                    alt="Invest Salone"
                                    className="ml-4 mt-16"
                                />
                            </div>
                            <div className="md:w-2/3 py-10 px-12">
                                <h2 className="text-blue-500">INVEST SALONE</h2>
                                <p className="text-justify">
                                    Invest Salone is a private sector development initiative that
                                    aims to help Sierra Leone realise the benefits of
                                    international trade and raise the incomes of 370,000 people by
                                    2025. It plans to achieve this by reducing the risk of
                                    investment in Sierra Leone and supporting market development.
                                    Invest Salone is working to reduce the cost and risk of doing
                                    business in Sierra Leone. It also helps firms to grow using a
                                    spectrum of tools, including brokering links between producers
                                    and buyers and technical assistance. Invest Salone works
                                    across four key sectors: agriculture, fisheries, manufacturing
                                    and tourism. (source: investsalone.com)
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default partners
